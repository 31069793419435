.navmain {
    display: flex;
    color: #000000;
    background-color: #FFFFFF;
    justify-content: space-between;
    height: 56px;
}

.logo {
    margin-top: 10px;
    height: 40px;
    width: 40px;
    margin-left: 16px;
}

.navul {
    display: flex;
    padding-left: 0%;
}

.navli{
    margin-top: 8px;
    font-size: 20px;
    font-family: "VodafoneLt-Regular";
    list-style-type: none;
    margin-left: 40px;
}

.btnNavItems {
    background-color: #FFFFFF;
    padding: 8px 0px;
    border: none;
}
.logoutbtndiv{
    display: flex;
    justify-content: end;   
}
.logoutbtn {
    color:#E60000;
    font-size: 18px;
    font-family: "VodafoneRg-Regular";
    cursor: pointer;
    text-decoration: underline #E60000;   
    margin-bottom: 10px;
    margin-top: 5px;
}

.selected {
    border-bottom: 3px solid #E60000 !important;
    color: #E60000 !important;
}
.usericon{
   margin: 10px 10px 0px 0px;
}
.navicon{
    display: flex;
    justify-content: space-between;
    /* flex-direction: column; */
}
.userdetail{
    color: #000000;
    font-size: 16px;
    font-family: "VodafoneRg-Regular";
}
.userlabel{
color: #666666;
font-size: 14px;
font-family: "VodafoneRg-Regular";
}
.userDiv{
    margin-bottom: 16px;
}
.notification{
    margin-top: 0px;
    /* margin-right: 6px; */
    height: 36px;
    width: 36px;
}
.notification{
    margin-right: 10px;
    margin-top: 15px;
}
.userIcon {
    height: 80px;
     width: 80px;
     border-radius: 100%;
     background-color: #007C92;
     margin-left: 10px; 
     color: #FFFFFF;
     font-size: 32.22px;
     text-align: center;
     display: flex;
     justify-content: center;
     align-items: center;
     font-family: "VodafoneRg-Regular"; 
     margin-right: 10px;
 }
 .usermain{
    display: flex;
    flex-direction: row;
 }
 .userdetailn{
    color: #333333;
    font-size: 20px;
    font-weight: 700;
    font-family: "VodafoneRg-Regular";
    word-break: break-word;
}
.userdetaile{
    color: #333333;
    font-size: 18px;
    font-weight: 400;
    font-family: "VodafoneRg-Regular";
    word-break: break-word;
}
.userdetailr{
    color: #333333;
    font-size: 18px;
    font-family: "VodafoneLt-Regular";
    /* margin-bottom: 5px;
    padding-top: 5px; */
}
.userdetaill{
    color: #333333;
    font-size: 16px;
    font-family: "VodafoneRg-Regular";
    border-top: 1px solid #EBEBEB;
    margin: 10px 0px 10px 0px;
    padding: 15px 0px 0px 0px;
    text-align: center;

}
.lastlogin{
    padding-bottom: 4px;
    padding-right: 5px;
}